/*
 * @Author: liuhanchuan 
 * @Date: 2021-12-20 14:39:18 
 * @Last Modified by: liuhanchuan
 * @Last Modified time: 2021-12-29 13:59:03
 */
<template>
    <el-dialog
        :visible.sync="dialogVisible"
        width="388px"
        :before-close="beforeClose"
        append-to-body
        :show-close="false"
        center
    >
        <div class="authority_box">
            <h2>已为您开通试用权限</h2>
            <p>
                <i class="el-icon-success"></i>
                <span>7天试用期限</span>
                <span>剩余{{ userInfo.remainTime }}天</span>
            </p>
            <p>
                <i class="el-icon-success"></i>
                <span>30条免费视频解析</span>
                <span>剩余{{ userInfo.remainVideoNumber }}条</span>
            </p>
            <div>
                <span @click="connectUs">联系我们</span>
                <img src="../../assets/images/homePage/service.jpg" alt="" v-show="imgVisible">
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: 'authorityDialog',
    data: function() {
        return {
            dialogVisible: false,
            imgVisible: false,
            userInfo: {}
        }
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if (this.userInfo.authorityVisible) {
            this.dialogVisible = true
        }
    },
    methods: {
        // 关闭7天权限提示弹框
        beforeClose() {
            localStorage.setItem('userInfo', JSON.stringify({
                ...this.userInfo,
                authorityVisible: false
            }))
            this.dialogVisible = false
        },
        connectUs() {
            this.imgVisible = !this.imgVisible
        }
    }
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
