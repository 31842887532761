<!-- 客群管理 -->
<template>
   <div class="content-box">
        <div class="left-wrap">
            <ul class="left-nav-list">
                <li
                    v-for="(item, index) in sideNavList"
                    :key="index"
                    :class="{ 'nav-active': item.isActive }"
                    @click="gotoPage(item.href)"
                >
                    {{ item.title }}
                </li>
            </ul>
        </div>
        <div class="right-wrap">
            <router-view/>
        </div>
        <AuthorityDialog />
    </div>
</template>

<script>
import AuthorityDialog from '@/components/authorityDialog'
export default {
  data() {
    return {
      sideNavList: [
        {
          title: "互动视频",
          href: "/interact_video",
          isActive: true
        },
        {
          title: '素材中心',
          href: '/material_center',
          isActive: false
        },
        {
          title: '数据统计',
          href: '/data_statistics',
          isActive: false
        },
      ]
    };
  },
  components: {
    AuthorityDialog
  },
  watch: {
    $route() {
      this.getActiveNav()
    }
  },
  methods: {
    // 获取默认index
    getActiveNav() {
      const path = this.$route.path;
      this.sideNavList.map(item => {
        item.isActive = path.indexOf(item.href) > -1;
        return item;
      });
    },
    //跳路由
    gotoPage(path) {
      this.$router.push({ path: `${path}` }).catch(err => err);
    },
  },
  created() {},
  mounted() {
    this.getActiveNav();
  },
 
};
</script>

